<footer>
    <div class="container">
        <div class="foot">
            <div class="logo">
                <img src="./assets/images/{{brand}}-miles/logo-footer.png" alt="">
            </div>
            <span class="learn-more" *ngIf="brand !== 'scotia'">
                <div class="bl-text bl-text--icon">
                    {{'LANDING.'+brand+'.FOOTER.questions' | translate}}
                    <a innerHTML="{{'LANDING.'+brand+'.FOOTER.click_here1' | translate}}" (click)="openUrl('https://www.wearenovae.com/')" target="_blank"></a>
                    <span class="text-program" innerHTML="{{'LANDING.'+brand+'.FOOTER.learn_more' | translate}}"></span>
        </div>
        </span>
        <span *ngIf="brand !== 'scotia'" innerHTML="{{'LANDING.'+brand+'.FOOTER.terms'| translate: { urlProgram: urlProgram } }}"></span>
        <div *ngIf="brand === 'scotia'" class="tc-scotia">
            <div class="bl-text bl-text--icon">
                <span innerHTML="{{'LANDING.'+brand+'.FOOTER.all_rights' | translate}}"></span>
                <a class="underline_none" innerHTML="{{'LANDING.'+brand+'.FOOTER.all_rights_click_here' | translate}}" (click)="openUrl('https://www.wearenovae.com/')" target="_blank"></a>
                <span innerHTML="{{'LANDING.'+brand+'.FOOTER.all_rights2' | translate}}"></span>

                <span class="text-program" innerHTML="{{'LANDING.'+brand+'.FOOTER.learn_more' | translate}}"></span>
            </div>

            <div class="bl-text">
                <span innerHTML="{{'LANDING.'+brand+'.FOOTER.terms' | translate}}"></span>
                <a (click)="loadUrl()" innerHTML="{{'LANDING.'+brand+'.FOOTER.term_click_here' | translate}}"></a>
                <span innerHTML="{{'LANDING.'+brand+'.FOOTER.terms2' | translate}}"></span>
            </div>

            <div class="bl-text" *ngIf="brand === 'scotia'">
                <span></span>
                <a (click)="openUrl('https://do.scotiabank.com/banca-personal/tarjetas-de-credito/programa-de-lealtad-scotiaclub0.html')"><u innerHTML="{{'LANDING.'+brand+'.FOOTER.scotia_club' | translate}}"></u></a>
                <span> &amp; </span>
                <a (click)="openUrl('https://do.scotiabank.com/banca-personal/tarjetas-de-credito/puntos-membership-rewards.html')"><u innerHTML="{{'LANDING.'+brand+'.FOOTER.membership_rewards' | translate}}"></u></a>
                <span innerHTML="{{'LANDING.'+brand+'.FOOTER.only_applicable' | translate}}"></span>
            </div>
        </div>
    </div>
    <span innerHTML="{{'LANDING.'+brand+'.FOOTER.all_brand' | translate}}"></span>
    </div>
</footer>