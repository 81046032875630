import { EProduct } from '../app/component/searched/models/searched.model';

export const typeApp = {
  NCB: 40,
  LIFEMILES: 38,
  SCOTIA: 41,
  MR: 52,
  CICB: 31,
  LIFEMILES_TRAVEL: 60,
  FC: 33,
  APAP: 62,
  CRYPTMI: 75
};

export const environment = {
  production: true,
  version: 'P v2.2.0(1)',

  uriPromociones: 'assets/data/promociones.json',
  uriSlider: 'assets/data/slider.json',
  pipeMilesFormat: '0.0-2',
  pipeCurrencyFormat: '1.2-2',

  subdomainBrands: {
    // Abanca
    'turecompensa': 'abanca',
    // Credomatic
    'credomatic': 'credomatic',
    // Lafise
    // 'lafise': 'lafise', // Se remueve para que no se tome en produccion
    // BancoPopular
    'popular.': 'bancopopular',
    // BancoIndustrial
    'bancoindustrial': 'bancoindustrial',
    // NCB
    'ncb': 'ncb',
    'ncbmiles.': 'ncb',
    // FirstCitizens
    'firstcitizens': 'firstcitizens',
    'fc.': 'firstcitizens',
    'fcgrewards': 'firstcitizens',
    'FCBRewards': 'firstcitizens',
    'myfcrewards': 'firstcitizens',
    // Scotia
    'scotia.': 'scotia',
    'scotia-rewards.': 'scotia',
    // BBVA
    'bbva.': 'bbva',
    // My Rewards
    'myrewards.': 'myrewards',
    'go2redeem.': 'myrewards',
    'mr2.sale': 'myrewards',
    // cibc
    'cibcfcibmiles.': 'cibc',
    'cibc': 'cibc',
    'baccredomatic.': 'credomatic',
    'uatbaccredomatic.allegra.tech.': 'credomatic',
    'uatcibcfcibmiles.allegra.tech ': 'cibc',
    'uatmr.allegra.tech': 'myrewards',
    'uatlifemiles.allegra.tech': 'lifeMilesTravel',
    'lifemiles.net': 'lifeMilesTravel',
    'lifemiles.com': 'lifeMilesTravel',
    'uatfc.allegra.tech': 'firstcitizens',
    'uatncb.allegra.tech': 'ncb',
    'nft.scotia-rewards.com': 'scotia',
    'uatapap.allegra.tech': 'apap',
    'apap-rewards.com': 'apap',
    'myrewardsapap.com': 'apap',
    'devalletravel.allegra.tech': 'firstcitizens',
    'myrewardsgobipuntos.com': 'bancoindustrial',
    'stagejun.allegra.tech': 'credomatic',
    'localhost': 'ncb'
  },

  /**
   * Tipos de valores de registrationType
   * 1. sin Registro
   * 2. pre-registro unicamente
   * 3. libre
  */
  brands: {
    novae:
    {
      novaeUniversalUrlBase: 'https://universalws.alle2020.com/',
      novaeUser: 'UNIVERSAL_APP_ID',
      novaePass: 'b^BJCYCA;wA2w[Y#',
      idSK: 'IVozZGcvRi1HcGAoXzh4JEIleyRuVXlZYXZycmQjJDU=',
      CONFIG_USER_GET_JWTTOKEN_URL: 'https://devuniversalws.allegra.tech/v1/getCountry',
      timeZoneDefault: 'QW1lcmljYS9Cb2dvdGE=',
      languajeTimeDefault: 'ZW4tVVM=',
      favicon_route: 'assets/favicon_alle/favicon.ico',
      channelId: 2,
      registrationType: 3,
      ccBin: '411111',
      defaultLanguaje: 'en',
      targetCurrency: 'USD',
      applicationId: '20',
      appName: 'Novae',
      lpBrandName: 'novae',
      useJwtToken: false,
      merchantAccountId: {
        benefits: 1,
        hotel: 2,
        flight: 3,
        activities: 4,
        travel: 5,
        cars: 6,
        transfers: 7,
        miles: 8,
        donate: 9,
        transitionStripe: 10

      },
      terminos_y_condiciones: {
        ES: 'https://wearenovae.com/miles/terms-and-conditions/en/index.html?programa=2&header=oculto',
        EN: 'https://wearenovae.com/miles/terms-and-conditions/en/index.html?programa=2&header=oculto'
      },
      config: {
        hasBenefits: true,
        hasActivities: true,
        hasHeaderDownload: false,
        hasTwoLanguages: true,
        isNewHome: false,
        isNewMenu: false,
        isLangNavigator: false
      }
    },
    upperclub: {
      novaeUniversalUrlBase: 'https://universalws.alle2020.com/',
      novaeUser: 'UNIVERSAL_APP_ID',
      novaePass: 'b^BJCYCA;wA2w[Y#',
      idSK: 'IVozZGcvRi1HcGAoXzh4JEIleyRuVXlZYXZycmQjJDU=',
      CONFIG_USER_GET_JWTTOKEN_URL: 'https://devuniversalws.allegra.tech/v1/getCountry',
      timeZoneDefault: 'QW1lcmljYS9Cb2dvdGE=',
      languajeTimeDefault: 'ZW4tVVM=',
      favicon_route: 'assets/favicon_alle/favicon.ico',
      channelId: 2,
      registrationType: 3,
      ccBin: '411111',
      defaultLanguaje: 'en',
      targetCurrency: 'USD',
      applicationId: '20',
      appName: 'UpperClub',
      lpBrandName: 'UP',
      useJwtToken: false,
      merchantAccountId: {
        benefits: 1,
        hotel: 2,
        flight: 3,
        activities: 4,
        travel: 5,
        cars: 6,
        transfers: 7,
        miles: 8,
        donate: 9
      },
      terminos_y_condiciones: {
        ES: 'https://wearenovae.com/carebynovae/t&c/esp/?hide',
        EN: 'https://wearenovae.com/carebynovae/t&c/esp/?hide'
      },
      config: {
        hasBenefits: true,
        hasActivities: true,
        hasHeaderDownload: false,
        hasTwoLanguages: true,
        isNewHome: false,
        isNewMenu: false,
        isLangNavigator: false
      }
    },
    abanca: {
      novaeUniversalUrlBase: 'https://universalws.alle2020.com/',
      novaeUser: 'UNIVERSAL_APP_ID',
      novaePass: 'b^BJCYCA;wA2w[Y#',
      idSK: 'IVozZGcvRi1HcGAoXzh4JEIleyRuVXlZYXZycmQjJDU=',
      CONFIG_USER_GET_JWTTOKEN_URL: 'https://devuniversalws.allegra.tech/v1/getCountry',
      timeZoneDefault: 'QW1lcmljYS9Cb2dvdGE=',
      languajeTimeDefault: 'ZW4tVVM=',
      favicon_route: 'assets/favicon_alle/favicon.ico',
      channelId: 3,
      registrationType: 3,
      ccBin: '411111',
      defaultLanguaje: 'en',
      targetCurrency: 'EUR',
      applicationId: '27',
      appName: 'ABANCA',
      lpBrandName: 'AB',
      useJwtToken: false,
      merchantAccountId: {
        hotel: 10,
        flight: 11,
        activities: 12,
        travel: 13,
        cars: 14,
        transfers: 16,
        miles: 16,
        benefits: 17,
      },
      terminos_y_condiciones: {
        ES: 'https://wearenovae.com/carebynovae/t&c/esp/?hide',
        EN: 'https://wearenovae.com/carebynovae/t&c/esp/?hide'
      },
      config: {
        hasBenefits: true,
        hasActivities: true,
        hasHeaderDownload: false,
        hasTwoLanguages: true,
        isNewHome: false,
        isNewMenu: false,
        isLangNavigator: false
      }
    },
    credomatic: {
      // novaeUniversalUrlBase: 'https://universalws.baccredomatic.voyage/',
      // novaeUniversalUrlBase: 'https://uat-universalws.baccredomatic.voyage/', //UAT
      novaeUniversalUrlBase: 'https://uat-universalws.baccredomatic.voyage/v1/getallpropertiesbyapplicationidVSV2',  //UAT Encrypt
      novaeUser: 'UNIVERSAL_APP_ID',
      novaePass: 'b^BJCYCA;wA2w[Y#',
      idSK: 'IVozZGcvRi1HcGAoXzh4JEIleyRuVXlZYXZycmQjJDU=',
      CONFIG_USER_GET_JWTTOKEN_URL: 'https://devuniversalws.allegra.tech/v1/getCountry',
      timeZoneDefault: 'QW1lcmljYS9Cb2dvdGE=',
      languajeTimeDefault: 'ZW4tVVM=',
      favicon_route: 'assets/favicomatic_credomatic/favicon.ico',
      channelId: 111,
      registrationType: 4,
      ccBin: '411111',
      defaultLanguaje: 'es',
      targetCurrency: 'USD',
      applicationId: '28',
      appName: 'BAC Credomatic',
      lpBrandName: 'CR',
      milesGiftDefault: '1000',
      speciali18n: true,
      useJwtToken: true,
      logo: 'icono-credomatic.png',
      //UAT - Encrypt
      UNIVERSAL_GET_JWTTOKEN_URL: 'aHR0cHM6Ly91YXQtdW5pdmVyc2Fsd3MuYmFjY3JlZG9tYXRpYy52b3lhZ2UvdjEvZ2V0SldUVG9rZW5WMg==',
      UNIVERSAL_GET_JWTTOKEN_UN: 'ZnJvbnRBdXRoRW5kQXBwQWRtaW4=',
      UNIVERSAL_GET_JWTTOKEN_PW: 'ZnJvbnRBdXRoRW5kQXBwQWRtaW4yMDE5KiM=',
      //PROD
      // UNIVERSAL_GET_JWTTOKEN_URL: 'aHR0cHM6Ly91bml2ZXJzYWx3cy5iYWNjcmVkb21hdGljLnZveWFnZS92MS9nZXRKV1RUb2tlbg==',
      // UNIVERSAL_GET_JWTTOKEN_UN: 'ZnJvbnRBdXRoRW5kQXBwQWRtaW4=',
      // UNIVERSAL_GET_JWTTOKEN_PW: 'ZnJvbnRBdXRoRW5kQXBwQWRtaW4yMDE5KiM=',
      //UAT
      reqPass: '1ZJF1&VxCz37@FvrJJtk^i',
      resPass: 'ww5drdk82l&O#h%RGoJTEY',
      urlSearchIframe: 'https://travel.cibcfcibmiles.com/',
      merchantAccountId: {
        benefits: 1,
        hotel: 2,
        flight: 3,
        activities: 4,
        travel: 5,
        cars: 6,
        transfers: 7,
        miles: 8,
        donate: 9
      },
      terminos_y_condiciones: {
        ES: 'https://bac-tc.milesbynovae.com/#/bac?lang=es',
        EN: 'https://bac-tc.milesbynovae.com/#/bac?lang=en'
      },
      config: {
        hasBenefits: true,
        hasActivities: true,
        hasHeaderDownload: true,
        hasTwoLanguages: false,
        isNewHome: true,
        isNewMenu: false,
        isLangNavigator: false,
        loadPromosFromDB: true,
        specialLoadPromos: true,
        loadCountries: true,
        showOtherServicesFooter: true,
        hasFirebase: true,
        loadSliderDB: true
      },
      optionmenu: [
        /*         { label: 'NAV.travel', router: '/travel', tabSelect: [EProduct.TRAVEL] }, */
        { label: 'NAV.flights', router: '/flights', tabSelect: [EProduct.FLIGHTS] },
        { label: 'NAV.hotels', router: '/hotels', tabSelect: [EProduct.HOTELS] },
        { label: 'NAV.cars', router: '/cars', tabSelect: [EProduct.CARS] },
        { label: 'NAV.homeVacations', router: '/homeVacations', tabSelect: [EProduct.HOMEVACATIONS] },
        // { label: 'NAV.home', router: '/home', tabSelect: [EProduct.HOME] },
        { label: 'NAV.learnMore', router: '/home', tabSelect: [EProduct.HOME] },
        /*         { label: 'NAV.news', router: '/news', tabSelect: [EProduct.NEWS] }, */
        { label: 'NAV.upperclub', router: '/tutorials', tabSelect: [EProduct.TUTORIALS] }
      ],
      videosLandingEN: {
        sectionOne: {
          one: { url: 'https://player.vimeo.com/external/462698340.hd.mp4?s=4831102565ac2c2212934a453d5bd09530ff23c1&profile_id=175' },
          two: { url: 'https://player.vimeo.com/external/462698416.hd.mp4?s=ab891ae01fe13eb9e1f4927f6de49f984d5919e3&profile_id=175' },
          three: { url: 'https://player.vimeo.com/external/463606914.hd.mp4?s=db825b2b33e4796871325a2db3e92aed1fbb3e73&profile_id=175' }
        },
        sectionTwo: {
          one: { url: 'https://player.vimeo.com/external/462698781.hd.mp4?s=283cf3067ee649009c65da11de1b126a07235e7c&profile_id=175' },
          two: { url: 'https://player.vimeo.com/external/463304224.hd.mp4?s=9a735e7da10d17023936406d713cbfb2e1a859cf&profile_id=175' },
          three: { url: 'https://player.vimeo.com/external/463577289.hd.mp4?s=578a08311c6f5b60c33ccd510b4cff1d3891177c&profile_id=175' }
        },
        sectionThree: {
          one: { url: 'https://player.vimeo.com/external/515326461.hd.mp4?s=eb4b66ff58bea394a7fbfa8ca99cd728bf3ef98f&profile_id=175' },
          two: { url: 'https://player.vimeo.com/external/544781327.hd.mp4?s=ca2dda18a72a5a1bd372f05b1fa14ab885435ef0&profile_id=175' },
          three: { url: 'https://player.vimeo.com/external/515327264.hd.mp4?s=cd27bce784fea93c696b536eff504731f2390155&profile_id=175' },
          four: { url: 'https://player.vimeo.com/external/515323417.hd.mp4?s=c8529820a82228f1fb7fd389c3f67a922aad45c9&profile_id=175' }
        }
      },
      videosLandingES: {
        sectionOne: {
          one: { url: 'https://player.vimeo.com/external/463492992.hd.mp4?s=3ca8d80eed54324cbc192c8b42a02edcc28675bc&profile_id=175' },
          two: { url: 'https://player.vimeo.com/external/463493089.hd.mp4?s=07143435615817a02aab44f2a1099ce4c43705cc&profile_id=175' },
          three: { url: 'https://player.vimeo.com/external/463606881.hd.mp4?s=2873823cf83208952b4f8f969a9040347394540d&profile_id=175' }
        },
        sectionTwo: {
          one: { url: 'https://player.vimeo.com/external/470228705.hd.mp4?s=d198b0fe09e65353b545204717884ce24d29e04c&profile_id=175' },
          two: { url: 'https://player.vimeo.com/external/463493247.hd.mp4?s=d00a20fde5488d6ad75b9250c13770c9e14b2bd7&profile_id=175' },
          three: { url: 'https://player.vimeo.com/external/463577277.hd.mp4?s=4b9301389acd3d8f05c0ab8776037d97934e5e95&profile_id=175' }
        },
        sectionThree: {
          one: { url: 'https://player.vimeo.com/external/515326461.hd.mp4?s=eb4b66ff58bea394a7fbfa8ca99cd728bf3ef98f&profile_id=175' },
          two: { url: 'https://player.vimeo.com/external/544781327.hd.mp4?s=ca2dda18a72a5a1bd372f05b1fa14ab885435ef0&profile_id=175' },
          three: { url: 'https://player.vimeo.com/external/515327264.hd.mp4?s=cd27bce784fea93c696b536eff504731f2390155&profile_id=175' },
          four: { url: 'https://player.vimeo.com/external/515323417.hd.mp4?s=c8529820a82228f1fb7fd389c3f67a922aad45c9&profile_id=175' }
        }
      }
    },
    cibc: {
      novaeUniversalUrlBase: 'https://universalws.cibcfcibmiles.com/',
      novaeUser: 'UNIVERSAL_APP_ID',
      novaePass: 'b^BJCYCA;wA2w[Y#',
      idSK: 'IVozZGcvRi1HcGAoXzh4JEIleyRuVXlZYXZycmQjJDU=',
      CONFIG_USER_GET_JWTTOKEN_URL: 'https://universalws.go2redeem.com/v1/getCountry',
      timeZoneDefault: 'QW1lcmljYS9Cb2dvdGE=',
      languajeTimeDefault: 'ZW4tVVM=',
      favicon_route: 'assets/favicon_alle/favicon.ico',
      channelId: 16,
      registrationType: 4,
      ccBin: '411111',
      defaultLanguaje: 'en',
      targetCurrency: 'USD',
      applicationId: '31',
      appName: 'CIBC Caribbean My Rewards',
      lpBrandName: 'CIBC',
      logo: 'icon-cibc.png',
      speciali18n: true,
      useJwtToken: false,
      callGetCountry: false,
      urlSearchIframe: 'https://travel.cibcfcibmiles.com/',
      merchantAccountId: {
        benefits: 1,
        hotel: 2,
        flight: 3,
        activities: 4,
        travel: 5,
        cars: 6,
        transfers: 7,
        miles: 8,
        donate: 9
      },
      terminos_y_condiciones: {
        ES: 'https://cibcfcib-tc.milesbynovae.com',
        EN: 'https://cibcfcib-tc.milesbynovae.com'
      },
      config: {
        hasBenefits: false,
        hasActivities: true,
        hasHeaderDownload: true,
        hasTwoLanguages: false,
        isNewHome: true,
        isNewMenu: true,
        isLangNavigator: false,
        loadPromosFromDB: true,
        specialLoadPromos: false,
        hasFirebase: true
      },
      optionmenu: [
        { label: 'NAV.home', router: '/home', tabSelect: [EProduct.HOME] },
        { label: 'NAV.travel', router: '/travel', tabSelect: [EProduct.TRAVEL] }
      ],
      videosLandingEN: {
        sectionOne: {
          one: { url: '	https://player.vimeo.com/progressive_redirect/playback/910874368/rendition/720p/file.mp4?loc=external&signature=cdbfb13ea307bd20d4a8308682c051cdeeffb105cdb80593c87520a018d4a2f0' },
          two: { url: 'https://player.vimeo.com/progressive_redirect/playback/910874616/rendition/720p/file.mp4?loc=external&signature=3802931e036cc43dcbb52216e33240c3b0bfa2f8bbacc445270951ecfab99b3b' },
          three: { url: 'https://player.vimeo.com/external/463606914.hd.mp4?s=db825b2b33e4796871325a2db3e92aed1fbb3e73&profile_id=175' }
        },
        sectionTwo: {
          one: { url: 'https://player.vimeo.com/progressive_redirect/playback/910876706/rendition/720p/file.mp4?loc=external&signature=5d74f2401ebb68a2a921790d1907396a0e582c11765a353fed08465f360a1490' },
          two: { url: 'https://player.vimeo.com/progressive_redirect/playback/910876347/rendition/720p/file.mp4?loc=external&signature=4835f4fa2924073019a17734d998e7e88166a1a06b0695f98fdb7c6b0240589d' },
          three: { url: 'https://player.vimeo.com/progressive_redirect/playback/910877014/rendition/720p/file.mp4?loc=external&signature=403ac2592b04e472f17942173dc7e207db21c53fe809290817122df3d8b8e605' }
        },
        sectionThree: {
          one: { url: '	https://player.vimeo.com/progressive_redirect/playback/910877263/rendition/720p/file.mp4?loc=external&signature=56f1f98e89ad92fa3a7771ee0dda6f0b13ca58642546719dbb549366953dcc05' },
          two: { url: 'https://player.vimeo.com/external/466627566.hd.mp4?s=cda45e4027da9864425f665c9469c290bad14af7&profile_id=175' },
          three: { url: 'https://player.vimeo.com/external/466629134.hd.mp4?s=cb3c0ad036fb338dc83446b2a417bc8ca6b6eb33&profile_id=175' }
        }
      },
    },
    apap: {
      novaeUniversalUrlBase: 'https://universalws.apap-rewards.com/', // 'https://universalws.apap-rewards.com/',
      novaeUser: 'UNIVERSAL_APP_ID',
      novaePass: 'b^BJCYCA;wA2w[Y#',
      idSK: 'IVozZGcvRi1HcGAoXzh4JEIleyRuVXlZYXZycmQjJDU=',
      CONFIG_USER_GET_JWTTOKEN_URL: 'https://devuniversalws.allegra.tech/v1/getCountry',
      timeZoneDefault: 'QW1lcmljYS9Cb2dvdGE=',
      languajeTimeDefault: 'ZW4tVVM=',
      favicon_route: 'assets/favicon_alle/favicon.ico',
      channelId: 28,
      registrationType: 4,
      ccBin: '411111',
      defaultLanguaje: 'es',
      targetCurrency: 'USD',
      applicationId: '62',
      logo: 'icon-apap.png',
      appName: 'My Rewards APAP',
      lpBrandName: 'APAP',
      speciali18n: true,
      useJwtToken: false,
      merchantAccountId: {
        benefits: 1,
        hotel: 2,
        flight: 3,
        activities: 4,
        travel: 5,
        cars: 6,
        transfers: 7,
        miles: 8,
        donate: 9
      },
      terminos_y_condiciones: {
        ES: 'https://apap-tc.milesbynovae.com/#/apap',
        EN: 'https://apap-tc.milesbynovae.com/#/apap'
      },
      config: {
        hasBenefits: false,
        hasActivities: true,
        hasHeaderDownload: true,
        hasTwoLanguages: false,
        isNewHome: true,
        isNewMenu: true,
        isLangNavigator: false,
        hasFirebase: true
      },
      optionmenu: [
        { label: 'NAV.home', router: '/home', tabSelect: [EProduct.HOME] },
        { label: 'NAV.travel', router: '/travel', tabSelect: [EProduct.TRAVEL] }
      ],
      videosLandingEN: {
        sectionOne: {
          one: { url: 'https://player.vimeo.com/external/511294676.hd.mp4?s=79377bcd48698c765ae7936462ed560b22662550&profile_id=175' },
          two: { url: 'https://player.vimeo.com/external/511294557.hd.mp4?s=ae391823af7a1af10592fb3cd8b62247115b8bb7&profile_id=175' },
          three: { url: 'https://player.vimeo.com/external/463606914.hd.mp4?s=db825b2b33e4796871325a2db3e92aed1fbb3e73&profile_id=175' }
        },
        sectionTwo: {
          one: { url: 'https://player.vimeo.com/external/511294402.hd.mp4?s=b3838bf168da4d93879de48785f9bb5b930e51ed&profile_id=175' },
          two: { url: 'https://player.vimeo.com/external/511294948.hd.mp4?s=28c4e07dcb2fa31d85ea30493ca273f0c9ee9c77&profile_id=175' },
          three: { url: 'https://player.vimeo.com/external/511294807.hd.mp4?s=bdaf9d438d31e251f8f777edd46b3e7be7775841&profile_id=175' }
        },
        sectionThree: {
          one: { url: 'https://player.vimeo.com/external/511293816.hd.mp4?s=9d59cae18607eed9e3297280d3c1fb1be3bfae65&profile_id=175' },
          two: { url: 'https://player.vimeo.com/external/466627566.hd.mp4?s=cda45e4027da9864425f665c9469c290bad14af7&profile_id=175' },
          three: { url: 'https://player.vimeo.com/external/511295179.hd.mp4?s=46fdefac7eb6745d92b655dd9e7a86d5212e1188&profile_id=175' }
        }
      },
      videosLandingES: {
        sectionOne: {
          one: { url: 'https://player.vimeo.com/external/511294676.hd.mp4?s=79377bcd48698c765ae7936462ed560b22662550&profile_id=175' },
          two: { url: 'https://player.vimeo.com/external/511294557.hd.mp4?s=ae391823af7a1af10592fb3cd8b62247115b8bb7&profile_id=175' },
          three: { url: 'https://player.vimeo.com/external/463606914.hd.mp4?s=db825b2b33e4796871325a2db3e92aed1fbb3e73&profile_id=175' }
        },
        sectionTwo: {
          one: { url: 'https://player.vimeo.com/external/511294402.hd.mp4?s=b3838bf168da4d93879de48785f9bb5b930e51ed&profile_id=175' },
          two: { url: 'https://player.vimeo.com/external/511294948.hd.mp4?s=28c4e07dcb2fa31d85ea30493ca273f0c9ee9c77&profile_id=175' },
          three: { url: 'https://player.vimeo.com/external/511294807.hd.mp4?s=bdaf9d438d31e251f8f777edd46b3e7be7775841&profile_id=175' }
        },
        sectionThree: {
          one: { url: 'https://player.vimeo.com/external/511293816.hd.mp4?s=9d59cae18607eed9e3297280d3c1fb1be3bfae65&profile_id=175' },
          two: { url: 'https://player.vimeo.com/external/466627566.hd.mp4?s=cda45e4027da9864425f665c9469c290bad14af7&profile_id=175' },
          three: { url: 'https://player.vimeo.com/external/511295179.hd.mp4?s=46fdefac7eb6745d92b655dd9e7a86d5212e1188&profile_id=175' }
        }
      },
    },
    firstcitizens: {
      novaeUniversalUrlBase: 'https://universalws.myfcrewards.com/',
      novaeUser: 'UNIVERSAL_APP_ID',
      novaePass: 'b^BJCYCA;wA2w[Y#',
      idSK: 'IVozZGcvRi1HcGAoXzh4JEIleyRuVXlZYXZycmQjJDU=',
      CONFIG_USER_GET_JWTTOKEN_URL: 'https://devuniversalws.allegra.tech/v1/getCountry',
      timeZoneDefault: 'QW1lcmljYS9Cb2dvdGE=',
      languajeTimeDefault: 'ZW4tVVM=',
      favicon_route: 'assets/favicon_alle/favicon.ico',
      channelId: 2,
      registrationType: 4,
      ccBin: '000009',
      defaultLanguaje: 'en',
      targetCurrency: 'USD',
      applicationId: '33',
      appName: 'First Citizens Rewards',
      lpBrandName: 'FC',
      logo: 'icon-first.png',
      phisicalCardSection: true,
      useJwtToken: false,
      speciali18n: true,
      urlSearchIframe: 'https://travel.myfcrewards.com/',
      merchantAccountId: {
        benefits: 1,
        hotel: 2,
        flight: 3,
        activities: 4,
        travel: 5,
        cars: 6,
        transfers: 7,
        miles: 8,
        donate: 9
      },
      terminos_y_condiciones: {
        ES: 'https://fc-tc.milesbynovae.com/?header=visible&programa=2',
        EN: 'https://fc-tc.milesbynovae.com/?header=visible&programa=2'
      },
      config: {
        hasBenefits: false,
        hasActivities: true,
        hasHeaderDownload: true,
        hasTwoLanguages: false,
        isNewHome: true,
        isNewMenu: true,
        isLangNavigator: false,
        hasFirebase: true
      },
      optionmenu: [
        { label: 'NAV.home', router: '/home', tabSelect: [EProduct.HOME] },
        { label: 'NAV.travel', router: '/travel', tabSelect: [EProduct.TRAVEL] }
      ],
      videosLanding: {
        sectionOne: {
          one: { url: 'https://player.vimeo.com/external/442566458.hd.mp4?s=06235f8ef4fddb66f54d5992080ae70ef13c9ca5&profile_id=175' },
          two: { url: 'https://player.vimeo.com/external/442582677.hd.mp4?s=421060d5035eaa7e25b2ec8adaa16c7b34f88889&profile_id=175' }
        },
        sectionTwo: {
          one: { url: 'https://player.vimeo.com/external/442482985.hd.mp4?s=612c3bbcafe33553e66f290dd0e7ae7891e442f9&profile_id=175' }
        },
        sectionThree: {
          one: { url: 'https://player.vimeo.com/external/437273328.hd.mp4?s=2c77f40e0ee7c3f27712af03a12ee3bb6f74fa24&profile_id=175' },
          two: { url: 'https://player.vimeo.com/external/548628280.hd.mp4?s=d30c5e8d77b76c3f57067204f732d942665d5cc7&profile_id=175' },
          three: { url: 'https://player.vimeo.com/external/548628214.hd.mp4?s=26ba4fc0d28e700453f69ba68f86328aba1be5cc&profile_id=175' }
        },
        sectionFour: {
          one: { url: 'https://player.vimeo.com/external/557306627.hd.mp4?s=3ab16260a61496bb403cba89f01e09a3a2c1175a&profile_id=175' },
          two: { url: 'https://player.vimeo.com/external/444708006.hd.mp4?s=d2d285dd3e105e2cf3ebc09c85aac3e00374424b&profile_id=175' }
        }
      }
    },
    miles: {
      novaeUniversalUrlBase: 'https://universalws.alle2020.com/',
      novaeUser: 'UNIVERSAL_APP_ID',
      novaePass: 'b^BJCYCA;wA2w[Y#',
      idSK: 'IVozZGcvRi1HcGAoXzh4JEIleyRuVXlZYXZycmQjJDU=',
      CONFIG_USER_GET_JWTTOKEN_URL: 'https://devuniversalws.allegra.tech/v1/getCountry',
      timeZoneDefault: 'QW1lcmljYS9Cb2dvdGE=',
      languajeTimeDefault: 'ZW4tVVM=',
      favicon_route: 'assets/favicon_alle/favicon.ico',
      channelId: 2,
      registrationType: 3,
      ccBin: '411111',
      defaultLanguaje: 'en',
      targetCurrency: 'USD',
      applicationId: '30',
      appName: 'Miles',
      lpBrandName: 'ML',
      useJwtToken: false,
      merchantAccountId: {
        benefits: 1,
        hotel: 2,
        flight: 3,
        activities: 4,
        travel: 5,
        cars: 6,
        transfers: 7,
        miles: 8,
        donate: 9
      },
      terminos_y_condiciones: {
        ES: 'https://wearenovae.com/carebynovae/t&c/esp/?hide',
        EN: 'https://wearenovae.com/carebynovae/t&c/esp/?hide'
      },
      config: {
        hasBenefits: false,
        hasActivities: true,
        hasHeaderDownload: false,
        hasTwoLanguages: true,
        isNewHome: false,
        isNewMenu: false,
        isLangNavigator: false
      }
    },
    myrewards: {
      novaeUniversalUrlBase: 'https://universalws.go2redeem.com/', //'https://devuniversalws.allegra.tech/',
      novaeUser: 'UNIVERSAL_APP_ID',
      novaePass: 'b^BJCYCA;wA2w[Y#',
      idSK: 'IVozZGcvRi1HcGAoXzh4JEIleyRuVXlZYXZycmQjJDU=',
      CONFIG_USER_GET_JWTTOKEN_URL: 'https://devuniversalws.allegra.tech/v1/getCountry',
      timeZoneDefault: 'QW1lcmljYS9Cb2dvdGE=',
      languajeTimeDefault: 'ZW4tVVM=',
      favicon_route: 'assets/favicon_alle/favicon.ico',
      channelId: 1,
      registrationType: 4,
      ccBin: '000009',
      defaultLanguaje: 'en',
      targetCurrency: 'USD',
      applicationId: '52',
      appName: 'My Rewards',
      lpBrandName: 'MR',
      speciali18n: true,
      useJwtToken: false,
      logo: 'icon-M.png',
      urlSearchIframe: 'https://junipertravel.go2redeem.com/',
      merchantAccountId: {
        benefits: 1,
        hotel: 2,
        flight: 3,
        activities: 4,
        travel: 5,
        cars: 6,
        transfers: 7,
        miles: 8,
        donate: 9
      },
      terminos_y_condiciones: {
        ES: 'https://mr-tc.milesbynovae.com/#/myrewards?lang=es',
        EN: 'https://mr-tc.milesbynovae.com/#/myrewards?lang=en'
      },
      config: {
        hasBenefits: false,
        hasActivities: true,
        hasHeaderDownload: true,
        hasTwoLanguages: true,
        isNewHome: true,
        isNewMenu: true,
        isLangNavigator: true,
        hasLandingSale: "mr2.sale",
        hasFirebase: true // Solo para produccion
      },
      optionmenu: [
        { label: 'NAV.home', router: '/home', tabSelect: [EProduct.HOME] },
        { label: 'NAV.travel', router: '/travel', tabSelect: [EProduct.TRAVEL] }
      ],
      videosLandingEN: {
        salesDeck: {
          one: { url: 'https://player.vimeo.com/external/457782359.hd.mp4?s=38b569e549a38852627d25d41e4bb9c61082fc14&profile_id=175' },
          two: { url: 'https://player.vimeo.com/external/462698340.hd.mp4?s=4831102565ac2c2212934a453d5bd09530ff23c1&profile_id=175' },
          three: { url: 'https://player.vimeo.com/external/462698340.hd.mp4?s=4831102565ac2c2212934a453d5bd09530ff23c1&profile_id=175' },
          four: { url: 'https://player.vimeo.com/external/463606914.hd.mp4?s=db825b2b33e4796871325a2db3e92aed1fbb3e73&profile_id=175' },
          five: { url: 'https://player.vimeo.com/external/463577355.hd.mp4?s=5215a6a8d4ea54b6459994b227692dbf9a3f8ff2&profile_id=175' },
          six: { url: 'https://player.vimeo.com/external/464391578.hd.mp4?s=3504d011d22aca77668707c703531892ec9f7eed&profile_id=175' },
          seven: { url: 'https://player.vimeo.com/external/489997807.hd.mp4?s=785e1ce1c5cba48ed4ff045b45597266b659b885&profile_id=175' },
          eight: { url: 'https://player.vimeo.com/external/463194609.hd.mp4?s=412f1a5f980043f446a3fab5e3080f3e05c408fe&profile_id=175 ' },
          nine: { url: 'https://player.vimeo.com/external/463577347.hd.mp4?s=94fa831e69335ed6184d72c2029e0f4b4cdd9672&profile_id=175' },
        },
        sectionOne: {
          one: { url: 'https://player.vimeo.com/external/462698340.hd.mp4?s=4831102565ac2c2212934a453d5bd09530ff23c1&profile_id=175' },
          two: { url: 'https://player.vimeo.com/external/462698416.hd.mp4?s=ab891ae01fe13eb9e1f4927f6de49f984d5919e3&profile_id=175' },
          three: { url: 'https://player.vimeo.com/external/463606914.hd.mp4?s=db825b2b33e4796871325a2db3e92aed1fbb3e73&profile_id=175' }
        },
        sectionTwo: {
          one: { url: 'https://player.vimeo.com/external/462698781.hd.mp4?s=283cf3067ee649009c65da11de1b126a07235e7c&profile_id=175' },
          two: { url: 'https://player.vimeo.com/external/463304224.hd.mp4?s=9a735e7da10d17023936406d713cbfb2e1a859cf&profile_id=175' },
          three: { url: 'https://player.vimeo.com/external/463577289.hd.mp4?s=578a08311c6f5b60c33ccd510b4cff1d3891177c&profile_id=175' }
        },
        sectionThree: {
          one: { url: 'https://player.vimeo.com/external/463577355.hd.mp4?s=5215a6a8d4ea54b6459994b227692dbf9a3f8ff2&profile_id=175' },
          two: { url: 'https://player.vimeo.com/external/464391578.hd.mp4?s=3504d011d22aca77668707c703531892ec9f7eed&profile_id=175' },
          three: { url: 'https://player.vimeo.com/external/463194609.hd.mp4?s=412f1a5f980043f446a3fab5e3080f3e05c408fe&profile_id=175' }
        }
      },
      videosLandingES: {
        salesDeck: {
          one: { url: 'https://player.vimeo.com/external/483277508.hd.mp4?s=c6641ede521c89711d2a1cfe48ce77ced64edcf5&profile_id=175' },
          two: { url: 'https://player.vimeo.com/external/489998035.hd.mp4?s=fc17eb9c638071023140bd2335f6858b7f16d75e&profile_id=175' },
          three: { url: 'https://player.vimeo.com/external/463492992.hd.mp4?s=3ca8d80eed54324cbc192c8b42a02edcc28675bc&profile_id=175' },
          four: { url: 'https://player.vimeo.com/external/463606881.hd.mp4?s=2873823cf83208952b4f8f969a9040347394540d&profile_id=175' },
          five: { url: 'https://player.vimeo.com/external/464391355.hd.mp4?s=149c858c0b5a27225b08922f2d0e6bf35e40049f&profile_id=175' },
          six: { url: 'https://player.vimeo.com/external/463577357.hd.mp4?s=c9e05c260010388bec99e471f448f721e855ccd2&profile_id=175' },
          seven: { url: 'https://player.vimeo.com/external/489998012.hd.mp4?s=83791f7e30d7bf02f58ce0a878cef94b7009752a&profile_id=175' },
          eight: { url: 'https://player.vimeo.com/external/463492555.hd.mp4?s=23cb6c9a95eb46cbcd0dc165d42d77f0785a6ffd&profile_id=175' },
          nine: { url: 'https://player.vimeo.com/external/463577418.hd.mp4?s=f6b01e6e8349ec30f061cce4a6cb7b7f5e9317a8&profile_id=175' },
        },
        sectionOne: {
          one: { url: 'https://player.vimeo.com/external/463492992.hd.mp4?s=3ca8d80eed54324cbc192c8b42a02edcc28675bc&profile_id=175' },
          two: { url: 'https://player.vimeo.com/external/463493089.hd.mp4?s=07143435615817a02aab44f2a1099ce4c43705cc&profile_id=175' },
          three: { url: 'https://player.vimeo.com/external/463606881.hd.mp4?s=2873823cf83208952b4f8f969a9040347394540d&profile_id=175' }
        },
        sectionTwo: {
          one: { url: 'https://player.vimeo.com/external/470228705.hd.mp4?s=d198b0fe09e65353b545204717884ce24d29e04c&profile_id=175' },
          two: { url: 'https://player.vimeo.com/external/463493247.hd.mp4?s=d00a20fde5488d6ad75b9250c13770c9e14b2bd7&profile_id=175' },
          three: { url: 'https://player.vimeo.com/external/463577277.hd.mp4?s=4b9301389acd3d8f05c0ab8776037d97934e5e95&profile_id=175' }
        },
        sectionThree: {
          one: { url: 'https://player.vimeo.com/external/463577357.hd.mp4?s=c9e05c260010388bec99e471f448f721e855ccd2&profile_id=175' },
          two: { url: 'https://player.vimeo.com/external/464391355.hd.mp4?s=149c858c0b5a27225b08922f2d0e6bf35e40049f&profile_id=175' },
          three: { url: 'https://player.vimeo.com/external/463492555.hd.mp4?s=23cb6c9a95eb46cbcd0dc165d42d77f0785a6ffd&profile_id=175' }
        }
      },
      otherLinksEN: {
        salesDeck: {
          whatsapp: { url: 'mailto:sales@novae.io' },
          pdf_1: { url: './assets/pdfs/myrewards-miles/SalesDeck-MR_ENG -NOV2020.pdf' },
          url_go2redeem: { url: 'https://go2redeem.com/#/home' }
        }
      },
      otherLinksES: {
        salesDeck: {
          whatsapp: { url: 'mailto:sales@novae.io' },
          pdf_1: { url: './assets/pdfs/myrewards-miles/SalesDeck-MR_SPA -NOV2020.pdf' },
          url_go2redeem: { url: 'https://go2redeem.com/#/home' }
        }
      }
    },
    milesCare: {
      novaeUniversalUrlBase: 'https://universalws.alle2020.com/',
      novaeUser: 'UNIVERSAL_APP_ID',
      novaePass: 'b^BJCYCA;wA2w[Y#',
      idSK: 'IVozZGcvRi1HcGAoXzh4JEIleyRuVXlZYXZycmQjJDU=',
      CONFIG_USER_GET_JWTTOKEN_URL: 'https://devuniversalws.allegra.tech/v1/getCountry',
      timeZoneDefault: 'QW1lcmljYS9Cb2dvdGE=',
      languajeTimeDefault: 'ZW4tVVM=',
      favicon_route: 'assets/favicon_alle/favicon.ico',
      channelId: 7,
      registrationType: 3,
      ccBin: '411111',
      defaultLanguaje: 'en',
      targetCurrency: 'USD',
      appName: 'LifeMile',
      lpBrandName: 'LM',
      useJwtToken: false,
      merchantAccountId: {
        benefits: 1,
        hotel: 2,
        flight: 3,
        activities: 4,
        travel: 5,
        cars: 6,
        transfers: 7,
        miles: 8,
        donate: 9
      },
      terminos_y_condiciones: {
        ES: 'https://wearenovae.com/milescare/terms-and-conditions/es/index.html?programa=4&header=oculto',
        EN: 'https://wearenovae.com/milescare/terms-and-conditions/en/index.html?programa=4&header=oculto'
      },
      config: {
        hasBenefits: true,
        hasActivities: true,
        hasHeaderDownload: false,
        isNewHome: false,
        isNewMenu: false,
        isLangNavigator: false
      }
    },
    bancoindustrial: {
      novaeUniversalUrlBase: 'https://universalws.corporacionbi-rewards.com/',
      novaeUser: 'UNIVERSAL_APP_ID',
      novaePass: 'b^BJCYCA;wA2w[Y#',
      idSK: 'IVozZGcvRi1HcGAoXzh4JEIleyRuVXlZYXZycmQjJDU=',
      CONFIG_USER_GET_JWTTOKEN_URL: 'https://devuniversalws.allegra.tech/v1/getCountry',
      timeZoneDefault: 'QW1lcmljYS9Cb2dvdGE=',
      languajeTimeDefault: 'ZW4tVVM=',
      favicon_route: 'assets/favicon_alle/favicon.ico',
      channelId: 114,
      registrationType: 4,
      ccBin: '411111',
      defaultLanguaje: 'es',
      targetCurrency: 'USD',
      applicationId: '39',
      appName: 'GO Bi Puntos',
      lpBrandName: 'BI',
      speciali18n: true,
      useJwtToken: true,
      UNIVERSAL_GET_JWTTOKEN_URL: 'aHR0cHM6Ly91bml2ZXJzYWx3cy5jb3Jwb3JhY2lvbmJpLXJld2FyZHMuY29tL3YxL2dldEpXVFRva2Vu',
      UNIVERSAL_GET_JWTTOKEN_UN: 'ZnJvbnRBdXRoRW5kQXBwQWRtaW4=',
      UNIVERSAL_GET_JWTTOKEN_PW: 'ZnJvbnRBdXRoRW5kQXBwQWRtaW4yMDE5KiM=',
      merchantAccountId: {
        benefits: 1,
        hotel: 2,
        flight: 3,
        activities: 4,
        travel: 5,
        cars: 6,
        transfers: 7,
        miles: 8,
        donate: 9
      },
      terminos_y_condiciones: {
        ES: 'https://wearenovae.com/carebynovae/t&c/esp/?hide',
        EN: 'https://wearenovae.com/carebynovae/t&c/esp/?hide'
      },
      config: {
        hasBenefits: false,
        hasActivities: true,
        hasHeaderDownload: false,
        hasTwoLanguages: true,
        isNewHome: true,
        isNewMenu: true,
        isLangNavigator: false
      },
      optionmenu: [
        { label: 'NAV.home', router: '/home', tabSelect: [EProduct.HOME] },
        { label: 'NAV.travel', router: '/travel', tabSelect: [EProduct.TRAVEL, EProduct.FLIGHTS, EProduct.HOTELS, EProduct.CARS, EProduct.ACTIVITIES, EProduct.BENEFITS] }
      ],
      videosLandingEN: {
        sectionOne: {
          one: { url: 'https://player.vimeo.com/external/462698340.hd.mp4?s=4831102565ac2c2212934a453d5bd09530ff23c1&profile_id=175' },
          two: { url: 'https://player.vimeo.com/external/462698416.hd.mp4?s=ab891ae01fe13eb9e1f4927f6de49f984d5919e3&profile_id=175' },
          three: { url: 'https://player.vimeo.com/external/463606914.hd.mp4?s=db825b2b33e4796871325a2db3e92aed1fbb3e73&profile_id=175' }
        },
        sectionTwo: {
          one: { url: 'https://player.vimeo.com/external/462698781.hd.mp4?s=283cf3067ee649009c65da11de1b126a07235e7c&profile_id=175' },
          two: { url: 'https://player.vimeo.com/external/463304224.hd.mp4?s=9a735e7da10d17023936406d713cbfb2e1a859cf&profile_id=175' },
          three: { url: 'https://player.vimeo.com/external/463577289.hd.mp4?s=578a08311c6f5b60c33ccd510b4cff1d3891177c&profile_id=175' }
        },
        sectionThree: {
          one: { url: 'https://player.vimeo.com/external/463577355.hd.mp4?s=5215a6a8d4ea54b6459994b227692dbf9a3f8ff2&profile_id=175' },
          two: { url: 'https://player.vimeo.com/external/464391578.hd.mp4?s=3504d011d22aca77668707c703531892ec9f7eed&profile_id=175' },
          three: { url: 'https://player.vimeo.com/external/463194609.hd.mp4?s=412f1a5f980043f446a3fab5e3080f3e05c408fe&profile_id=175' }
        }
      },
      videosLandingES: {
        sectionOne: {
          one: { url: 'https://player.vimeo.com/external/554310744.hd.mp4?s=037407c01904b49eaac9656c95f343af42653e39&profile_id=175' },
          two: { url: 'https://player.vimeo.com/external/554310796.hd.mp4?s=9623e643da2738e4baf533f6ad0c858a0ec8350f&profile_id=175' },
          three: { url: 'https://player.vimeo.com/external/463606881.hd.mp4?s=2873823cf83208952b4f8f969a9040347394540d&profile_id=175' }
        },
        sectionTwo: {
          one: { url: 'https://player.vimeo.com/external/554311004.hd.mp4?s=5cdd6764e732814263331f8e6de5a449d2b2c4e5&profile_id=175' },
          two: { url: 'https://player.vimeo.com/external/554310886.hd.mp4?s=30d9e241d856b2bb867acd599735aec41070e55a&profile_id=175' },
          three: { url: 'https://player.vimeo.com/external/554311051.hd.mp4?s=852c1389fcdd7faf6d79a24c3b9ff63b47b8c54a&profile_id=175' }
        },
        sectionThree: {
          one: { url: 'https://player.vimeo.com/external/554311155.hd.mp4?s=7d2570369818328e3d4935ed48d539c89bf4e20a&profile_id=175' },
          two: { url: 'https://player.vimeo.com/external/464391355.hd.mp4?s=149c858c0b5a27225b08922f2d0e6bf35e40049f&profile_id=175' },
          three: { url: 'https://player.vimeo.com/external/554310614.hd.mp4?s=c08e8a55e1f42decbd30c34974f87299c9ca5bd0&profile_id=175' }
        }
      }
    },
    bancopopular: {
      novaeUniversalUrlBase: 'https://universalws.alle2020.com/',
      novaeUser: 'UNIVERSAL_APP_ID',
      novaePass: 'b^BJCYCA;wA2w[Y#',
      idSK: 'IVozZGcvRi1HcGAoXzh4JEIleyRuVXlZYXZycmQjJDU=',
      CONFIG_USER_GET_JWTTOKEN_URL: 'https://devuniversalws.allegra.tech/v1/getCountry',
      timeZoneDefault: 'QW1lcmljYS9Cb2dvdGE=',
      languajeTimeDefault: 'ZW4tVVM=',
      favicon_route: 'assets/favicon_alle/favicon.ico',
      channelId: 2,
      registrationType: 3,
      ccBin: '411111',
      defaultLanguaje: 'en',
      targetCurrency: 'USD',
      applicationId: '35',
      appName: 'Banco Popular',
      lpBrandName: 'BP',
      useJwtToken: false,
      merchantAccountId: {
        benefits: 1,
        hotel: 2,
        flight: 3,
        activities: 4,
        travel: 5,
        cars: 6,
        transfers: 7,
        miles: 8,
        donate: 9
      },
      terminos_y_condiciones: {
        ES: 'https://wearenovae.com/carebynovae/t&c/esp/?hide',
        EN: 'https://wearenovae.com/carebynovae/t&c/esp/?hide'
      },
      config: {
        hasBenefits: false,
        hasActivities: true,
        hasHeaderDownload: false,
        hasTwoLanguages: true,
        isNewHome: false,
        isNewMenu: false,
        isLangNavigator: false
      }
    },
    lafise: {
      novaeUniversalUrlBase: 'https://universalws.alle2020.com/',
      novaeUser: 'UNIVERSAL_APP_ID',
      novaePass: 'b^BJCYCA;wA2w[Y#',
      idSK: 'IVozZGcvRi1HcGAoXzh4JEIleyRuVXlZYXZycmQjJDU=',
      CONFIG_USER_GET_JWTTOKEN_URL: 'https://devuniversalws.allegra.tech/v1/getCountry',
      timeZoneDefault: 'QW1lcmljYS9Cb2dvdGE=',
      languajeTimeDefault: 'ZW4tVVM=',
      favicon_route: 'assets/favicon_alle/favicon.ico',
      channelId: 2,
      registrationType: 3,
      ccBin: '411111',
      defaultLanguaje: 'en',
      targetCurrency: 'USD',
      applicationId: '38',
      useJwtToken: false,
      merchantAccountId: {
        benefits: 1,
        hotel: 2,
        flight: 3,
        activities: 4,
        travel: 5,
        cars: 6,
        transfers: 7,
        miles: 8,
        donate: 9
      },
      terminos_y_condiciones: {
        ES: 'https://wearenovae.com/miles/terms-and-conditions/en/index.html?programa=2&header=oculto',
        EN: 'https://wearenovae.com/miles/terms-and-conditions/en/index.html?programa=2&header=oculto'
      },
      config: {
        hasBenefits: true,
        hasActivities: true,
        hasHeaderDownload: false,
        hasTwoLanguages: true,
        isNewHome: false,
        isNewMenu: false,
        isLangNavigator: false
      }
    },
    ncb: {
      novaeUniversalUrlBase: 'https://universalws.ncbmiles.com/',
      // novaeUniversalUrlBase: 'https://devuniversalws.allegra.tech/',
      novaeUser: 'UNIVERSAL_APP_ID',
      novaePass: 'b^BJCYCA;wA2w[Y#',
      idSK: 'IVozZGcvRi1HcGAoXzh4JEIleyRuVXlZYXZycmQjJDU=',
      CONFIG_USER_GET_JWTTOKEN_URL: 'https://devuniversalws.allegra.tech/v1/getCountry',
      timeZoneDefault: 'QW1lcmljYS9Cb2dvdGE=',
      languajeTimeDefault: 'ZW4tVVM=',
      favicon_route: 'assets/favicon_alle/favicon.ico',
      channelId: 19,
      registrationType: 4,
      ccBin: '000008',
      defaultLanguaje: 'en',
      targetCurrency: 'USD',
      applicationId: '40',
      appName: 'NCB Miles',
      lpBrandName: 'NCB',
      logo: 'icono_NCB.png',
      phisicalCardSection: false,
      useJwtToken: false,
      speciali18n: true,
      urlSearchIframe: 'https://travel.ncbmiles.com/',
      merchantAccountId: {
        benefits: 1,
        hotel: 2,
        flight: 3,
        activities: 4,
        travel: 5,
        cars: 6,
        transfers: 7,
        miles: 8,
        donate: 9
      },
      terminos_y_condiciones: {
        ES: 'https://ncb-tc.milesbynovae.com/?programa=2&header=oculto',
        EN: 'https://ncb-tc.milesbynovae.com/?programa=2&header=oculto'
      },
      config: {
        hasBenefits: false,
        hasActivities: true,
        hasAbout: true,
        hasHeaderDownload: true,
        hasTwoLanguages: false,
        isNewHome: true,
        isNewMenu: true,
        isLangNavigator: false,
        hasFirebase: true
      },
      optionmenu: [
        { label: 'NAV.about', router: '/home', tabSelect: [EProduct.HOME] },
        { label: 'NAV.myRewards', router: '/myRewards', tabSelect: [EProduct.REWARDS] },
        { label: 'NAV.travel', router: '/travel', tabSelect: [EProduct.TRAVEL] }
      ],
      videosLanding: {
        sectionOne: {
          one: { url: 'https://player.vimeo.com/external/442847935.hd.mp4?s=806c17fc999bd9f72e66581d3789264ce2eb2fd2&profile_id=175' },
          two: { url: 'https://player.vimeo.com/external/442847935.hd.mp4?s=806c17fc999bd9f72e66581d3789264ce2eb2fd2&profile_id=175' }
        },
        sectionTwo: {
          one: { url: 'https://player.vimeo.com/external/435867854.hd.mp4?s=0431e071befd66345b11d1e78caa575cfc8e041c&profile_id=175' }
        },
        sectionThree: {
          one: { url: 'https://player.vimeo.com/external/437273328.hd.mp4?s=2c77f40e0ee7c3f27712af03a12ee3bb6f74fa24&profile_id=175' },
          two: { url: 'https://player.vimeo.com/external/507744679.hd.mp4?s=53f711cf880dac8dd0cfefc7adddc7ed981904b8&profile_id=175' },
          three: { url: 'https://player.vimeo.com/external/557823505.hd.mp4?s=0d6184e54a125ca7753187df2117e62ae340b235&profile_id=175' }
        },
        sectionFour: {
          one: { url: 'https://player.vimeo.com/external/561097101.hd.mp4?s=c00e6ed38fece8b49a4afbda82787f12f73011ec&profile_id=175' },
          two: { url: 'https://player.vimeo.com/external/558647493.hd.mp4?s=7a1932dc5646cf8421369b9da28ceaa0db4806f5&profile_id=175 ' }
        }
      },
      videosLandingEN: {
        salesDeck: {
          one: { url: 'https://player.vimeo.com/external/457782359.hd.mp4?s=38b569e549a38852627d25d41e4bb9c61082fc14&profile_id=175' },
          two: { url: 'https://player.vimeo.com/external/462698340.hd.mp4?s=4831102565ac2c2212934a453d5bd09530ff23c1&profile_id=175' },
          three: { url: 'https://player.vimeo.com/external/462698340.hd.mp4?s=4831102565ac2c2212934a453d5bd09530ff23c1&profile_id=175' },
          four: { url: 'https://player.vimeo.com/external/463606914.hd.mp4?s=db825b2b33e4796871325a2db3e92aed1fbb3e73&profile_id=175' },
          five: { url: 'https://player.vimeo.com/external/463577355.hd.mp4?s=5215a6a8d4ea54b6459994b227692dbf9a3f8ff2&profile_id=175' },
          six: { url: 'https://player.vimeo.com/external/464391578.hd.mp4?s=3504d011d22aca77668707c703531892ec9f7eed&profile_id=175' },
          seven: { url: 'https://player.vimeo.com/external/489997807.hd.mp4?s=785e1ce1c5cba48ed4ff045b45597266b659b885&profile_id=175' },
          eight: { url: 'https://player.vimeo.com/external/463194609.hd.mp4?s=412f1a5f980043f446a3fab5e3080f3e05c408fe&profile_id=175 ' },
          nine: { url: 'https://player.vimeo.com/external/463577347.hd.mp4?s=94fa831e69335ed6184d72c2029e0f4b4cdd9672&profile_id=175' },
        },
        sectionOne: {
          one: { url: 'https://player.vimeo.com/external/462698340.hd.mp4?s=4831102565ac2c2212934a453d5bd09530ff23c1&profile_id=175' },
          two: { url: 'https://player.vimeo.com/external/462698416.hd.mp4?s=ab891ae01fe13eb9e1f4927f6de49f984d5919e3&profile_id=175' },
          three: { url: 'https://player.vimeo.com/external/463606914.hd.mp4?s=db825b2b33e4796871325a2db3e92aed1fbb3e73&profile_id=175' }
        },
        sectionTwo: {
          one: { url: 'https://player.vimeo.com/external/462698781.hd.mp4?s=283cf3067ee649009c65da11de1b126a07235e7c&profile_id=175' },
          two: { url: 'https://player.vimeo.com/external/463304224.hd.mp4?s=9a735e7da10d17023936406d713cbfb2e1a859cf&profile_id=175' },
          three: { url: 'https://player.vimeo.com/external/463577289.hd.mp4?s=578a08311c6f5b60c33ccd510b4cff1d3891177c&profile_id=175' }
        },
        sectionThree: {
          one: { url: 'https://player.vimeo.com/external/463577355.hd.mp4?s=5215a6a8d4ea54b6459994b227692dbf9a3f8ff2&profile_id=175' },
          two: { url: 'https://player.vimeo.com/external/464391578.hd.mp4?s=3504d011d22aca77668707c703531892ec9f7eed&profile_id=175' },
          three: { url: 'https://player.vimeo.com/external/463194609.hd.mp4?s=412f1a5f980043f446a3fab5e3080f3e05c408fe&profile_id=175' }
        }
      },
      videosLandingES: {
        salesDeck: {
          one: { url: 'https://player.vimeo.com/external/483277508.hd.mp4?s=c6641ede521c89711d2a1cfe48ce77ced64edcf5&profile_id=175' },
          two: { url: 'https://player.vimeo.com/external/489998035.hd.mp4?s=fc17eb9c638071023140bd2335f6858b7f16d75e&profile_id=175' },
          three: { url: 'https://player.vimeo.com/external/463492992.hd.mp4?s=3ca8d80eed54324cbc192c8b42a02edcc28675bc&profile_id=175' },
          four: { url: 'https://player.vimeo.com/external/463606881.hd.mp4?s=2873823cf83208952b4f8f969a9040347394540d&profile_id=175' },
          five: { url: 'https://player.vimeo.com/external/464391355.hd.mp4?s=149c858c0b5a27225b08922f2d0e6bf35e40049f&profile_id=175' },
          six: { url: 'https://player.vimeo.com/external/463577357.hd.mp4?s=c9e05c260010388bec99e471f448f721e855ccd2&profile_id=175' },
          seven: { url: 'https://player.vimeo.com/external/489998012.hd.mp4?s=83791f7e30d7bf02f58ce0a878cef94b7009752a&profile_id=175' },
          eight: { url: 'https://player.vimeo.com/external/463492555.hd.mp4?s=23cb6c9a95eb46cbcd0dc165d42d77f0785a6ffd&profile_id=175' },
          nine: { url: 'https://player.vimeo.com/external/463577418.hd.mp4?s=f6b01e6e8349ec30f061cce4a6cb7b7f5e9317a8&profile_id=175' },
        },
        sectionOne: {
          one: { url: 'https://player.vimeo.com/external/463492992.hd.mp4?s=3ca8d80eed54324cbc192c8b42a02edcc28675bc&profile_id=175' },
          two: { url: 'https://player.vimeo.com/external/463493089.hd.mp4?s=07143435615817a02aab44f2a1099ce4c43705cc&profile_id=175' },
          three: { url: 'https://player.vimeo.com/external/463606881.hd.mp4?s=2873823cf83208952b4f8f969a9040347394540d&profile_id=175' }
        },
        sectionTwo: {
          one: { url: 'https://player.vimeo.com/external/470228705.hd.mp4?s=d198b0fe09e65353b545204717884ce24d29e04c&profile_id=175' },
          two: { url: 'https://player.vimeo.com/external/463493247.hd.mp4?s=d00a20fde5488d6ad75b9250c13770c9e14b2bd7&profile_id=175' },
          three: { url: 'https://player.vimeo.com/external/463577277.hd.mp4?s=4b9301389acd3d8f05c0ab8776037d97934e5e95&profile_id=175' }
        },
        sectionThree: {
          one: { url: 'https://player.vimeo.com/external/463577357.hd.mp4?s=c9e05c260010388bec99e471f448f721e855ccd2&profile_id=175' },
          two: { url: 'https://player.vimeo.com/external/464391355.hd.mp4?s=149c858c0b5a27225b08922f2d0e6bf35e40049f&profile_id=175' },
          three: { url: 'https://player.vimeo.com/external/463492555.hd.mp4?s=23cb6c9a95eb46cbcd0dc165d42d77f0785a6ffd&profile_id=175' }
        }
      },
      otherLinksEN: {
        salesDeck: {
          whatsapp: { url: 'mailto:sales@novae.io' },
          pdf_1: { url: './assets/pdfs/myrewards-miles/SalesDeck-MR_ENG - APRIL2021.pdf' },
          url_go2redeem: { url: 'https://go2redeem.com/#/home' }
        }
      },
      otherLinksES: {
        salesDeck: {
          whatsapp: { url: 'mailto:sales@novae.io' },
          pdf_1: { url: './assets/pdfs/myrewards-miles/SalesDeck-MR_SPA - APRIL2021.pdf' },
          url_go2redeem: { url: 'https://go2redeem.com/#/home' }
        }
      }
    },
    scotia: {
      //novaeUniversalUrlBase: 'https://uatuniversalws.scotia-rewards.com/', //UAT
      novaeUniversalUrlBase: 'https://universalws.scotia-rewards.com/',     //PROD
      novaeUser: 'UNIVERSAL_APP_ID',
      novaePass: 'b^BJCYCA;wA2w[Y#',
      idSK: 'IVozZGcvRi1HcGAoXzh4JEIleyRuVXlZYXZycmQjJDU=',
      CONFIG_USER_GET_JWTTOKEN_URL: 'https://devuniversalws.allegra.tech/v1/getCountry',
      timeZoneDefault: 'QW1lcmljYS9Cb2dvdGE=',
      languajeTimeDefault: 'ZW4tVVM=',
      favicon_route: 'assets/favicon_alle/favicon.ico',
      channelId: 2,
      registrationType: 4,
      ccBin: '411111',
      defaultLanguaje: 'en',
      targetCurrency: 'USD',
      applicationId: '41',
      appName: 'Scotia Rewards',
      lpBrandName: 'SC',
      subBrand: true,
      speciali18n: true,
      urlSearchIframe: 'https://travel.cibcfcibmiles.com/',
      useJwtToken: true,
      //UNIVERSAL_GET_JWTTOKEN_URL: 'aHR0cHM6Ly91YXR1bml2ZXJzYWx3cy5zY290aWEtcmV3YXJkcy5jb20vdjEvZ2V0SldUVG9rZW4K', //UAT
      UNIVERSAL_GET_JWTTOKEN_URL: 'aHR0cHM6Ly91bml2ZXJzYWx3cy5zY290aWEtcmV3YXJkcy5jb20vdjEvZ2V0SldUVG9rZW4=',  //PROD
      UNIVERSAL_GET_JWTTOKEN_UN: 'ZnJvbnRBdXRoRW5kQXBwQWRtaW4=',
      UNIVERSAL_GET_JWTTOKEN_PW: 'ZnJvbnRBdXRoRW5kQXBwQWRtaW4yMDE4KiM=',
      merchantAccountId: {
        benefits: 1,
        hotel: 2,
        flight: 3,
        activities: 4,
        travel: 5,
        cars: 6,
        transfers: 7,
        miles: 8,
        donate: 9
      },
      terminos_y_condiciones: {
        ES: 'https://wearenovae.com/miles/terms-and-conditions/en/index.html?programa=2&header=oculto',
        EN: 'https://wearenovae.com/miles/terms-and-conditions/en/index.html?programa=2&header=oculto'
      },
      config: {
        hasBenefits: false,
        hasActivities: true,
        hasHeaderDownload: true,
        hasTwoLanguages: true,
        isNewHome: true,
        isNewMenu: true,
        isLangNavigator: false,
        showButtonWhatsApp: false,
        hasFirebase: true
      },
      optionmenu: [
        { label: 'NAV.home', router: '/home', tabSelect: [EProduct.HOME] },
        { label: 'NAV.travel', router: '/travel', tabSelect: [EProduct.TRAVEL] }
      ],
      videosLandingEN: {
        sectionOne: {
          one: { url: 'https://player.vimeo.com/external/462698340.hd.mp4?s=4831102565ac2c2212934a453d5bd09530ff23c1&profile_id=175' },
          two: { url: 'https://player.vimeo.com/external/462698416.hd.mp4?s=ab891ae01fe13eb9e1f4927f6de49f984d5919e3&profile_id=175' },
          three: { url: 'https://player.vimeo.com/external/463606914.hd.mp4?s=db825b2b33e4796871325a2db3e92aed1fbb3e73&profile_id=175' }
        },
        sectionTwo: {
          one: { url: 'https://player.vimeo.com/external/462698781.hd.mp4?s=283cf3067ee649009c65da11de1b126a07235e7c&profile_id=175' },
          two: { url: 'https://player.vimeo.com/external/463304224.hd.mp4?s=9a735e7da10d17023936406d713cbfb2e1a859cf&profile_id=175' },
          three: { url: 'https://player.vimeo.com/external/463577289.hd.mp4?s=578a08311c6f5b60c33ccd510b4cff1d3891177c&profile_id=175' }
        },
        sectionThree: {
          one: { url: 'https://player.vimeo.com/external/463577355.hd.mp4?s=5215a6a8d4ea54b6459994b227692dbf9a3f8ff2&profile_id=175' },
          two: { url: 'https://player.vimeo.com/external/464391578.hd.mp4?s=3504d011d22aca77668707c703531892ec9f7eed&profile_id=175' },
          three: { url: 'https://player.vimeo.com/external/463194609.hd.mp4?s=412f1a5f980043f446a3fab5e3080f3e05c408fe&profile_id=175' }
        }
      },
      videosLandingES: {
        sectionOne: {
          one: { url: 'https://player.vimeo.com/external/463492992.hd.mp4?s=3ca8d80eed54324cbc192c8b42a02edcc28675bc&profile_id=175' },
          two: { url: 'https://player.vimeo.com/external/463493089.hd.mp4?s=07143435615817a02aab44f2a1099ce4c43705cc&profile_id=175' },
          three: { url: 'https://player.vimeo.com/external/463606881.hd.mp4?s=2873823cf83208952b4f8f969a9040347394540d&profile_id=175' }
        },
        sectionTwo: {
          one: { url: 'https://player.vimeo.com/external/470228705.hd.mp4?s=d198b0fe09e65353b545204717884ce24d29e04c&profile_id=175' },
          two: { url: 'https://player.vimeo.com/external/463493247.hd.mp4?s=d00a20fde5488d6ad75b9250c13770c9e14b2bd7&profile_id=175' },
          three: { url: 'https://player.vimeo.com/external/463577277.hd.mp4?s=4b9301389acd3d8f05c0ab8776037d97934e5e95&profile_id=175' }
        },
        sectionThree: {
          one: { url: 'https://player.vimeo.com/external/463577357.hd.mp4?s=c9e05c260010388bec99e471f448f721e855ccd2&profile_id=175' },
          two: { url: 'https://player.vimeo.com/external/464391355.hd.mp4?s=149c858c0b5a27225b08922f2d0e6bf35e40049f&profile_id=175' },
          three: { url: 'https://player.vimeo.com/external/463492555.hd.mp4?s=23cb6c9a95eb46cbcd0dc165d42d77f0785a6ffd&profile_id=175' }
        }
      }
    },
    bbva: {
      novaeUniversalUrlBase: 'https://universalws.alle2020.com/',
      novaeUser: 'UNIVERSAL_APP_ID',
      novaePass: 'b^BJCYCA;wA2w[Y#',
      idSK: 'IVozZGcvRi1HcGAoXzh4JEIleyRuVXlZYXZycmQjJDU=',
      CONFIG_USER_GET_JWTTOKEN_URL: 'https://devuniversalws.allegra.tech/v1/getCountry',
      timeZoneDefault: 'QW1lcmljYS9Cb2dvdGE=',
      languajeTimeDefault: 'ZW4tVVM=',
      favicon_route: 'assets/favicon_alle/favicon.ico',
      channelId: 2,
      registrationType: 4,
      ccBin: '411111',
      defaultLanguaje: 'en',
      targetCurrency: 'USD',
      applicationId: '42',
      appName: 'BBVA',
      lpBrandName: 'BBVA',
      useJwtToken: false,
      merchantAccountId: {
        benefits: 1,
        hotel: 2,
        flight: 3,
        activities: 4,
        travel: 5,
        cars: 6,
        transfers: 7,
        miles: 8,
        donate: 9
      },
      terminos_y_condiciones: {
        ES: 'https://wearenovae.com/miles/terms-and-conditions/en/index.html?programa=2&header=oculto',
        EN: 'https://wearenovae.com/miles/terms-and-conditions/en/index.html?programa=2&header=oculto'
      },
      config: {
        hasBenefits: false,
        hasHeaderDownload: false,
        hasTwoLanguages: true,
        isNewHome: false,
        isNewMenu: false,
        isLangNavigator: false
      }
    },
    lifeMilesTravel: {
      novaeUniversalUrlBase: 'https://universalws.alle2020.com/',
      novaeUser: 'UNIVERSAL_APP_ID',
      novaePass: 'b^BJCYCA;wA2w[Y#',
      idSK: 'IVozZGcvRi1HcGAoXzh4JEIleyRuVXlZYXZycmQjJDU=',
      CONFIG_USER_GET_JWTTOKEN_URL: 'https://devuniversalws.allegra.tech/v1/getCountry',
      timeZoneDefault: 'QW1lcmljYS9Cb2dvdGE=',
      languajeTimeDefault: 'ZW4tVVM=',
      favicon_route: 'assets/favicon_alle/favicon.ico',
      channelId: 36,
      registrationType: 4,
      ccBin: '411111',
      defaultLanguaje: 'es',
      targetCurrency: 'USD',
      applicationId: '60',
      appName: 'LifeMiles Travel',
      lpBrandName: 'LifMiles',
      useJwtToken: false,
      merchantAccountId: {
        benefits: 1,
        hotel: 2,
        flight: 3,
        activities: 4,
        travel: 5,
        cars: 6,
        transfers: 7,
        miles: 8,
        donate: 9
      },
      terminos_y_condiciones: {
        ES: 'https://lmtravel-tc.milesbynovae.com/#/lifemiles-more-fare?lang=es',
        EN: 'https://lmtravel-tc.milesbynovae.com/#/lifemiles-more-fare?lang=en'
      },
      config: {
        hasBenefits: false,
        hasHeaderDownload: false,
        hasTwoLanguages: true,
        isNewHome: true,
        isNewMenu: true,
        isLangNavigator: false,
        hasFirebase: true
      }
    },
    cryptmi: {
      novaeUniversalUrlBase: 'https://devuniversalws.allegra.tech/',
      //novaeUniversalUrlBase: 'https://devuniversalws.allegra.tech/v1/getallpropertiesbyapplicationidVSV2',
      //novaeUniversalUrlBase: 'https://mb-universalws.allegra.tech/v1/getallpropertiesbyapplicationidVSV2',
      //novaeUniversalUrlBase: 'https://mb-universalws.allegra.tech/',
      novaeUser: 'UNIVERSAL_APP_ID',
      novaePass: 'b^BJCYCA;wA2w[Y#',
      idSK: 'IVozZGcvRi1HcGAoXzh4JEIleyRuVXlZYXZycmQjJDU=',
      favicon_route: 'assets/favicomatic_cryptmi/favicon.png',
      channelId: 120,
      registrationType: 4,
      ccBin: '000009',
      defaultLanguaje: 'en',
      targetCurrency: 'USD',
      applicationId: '75',
      appName: 'cryptMi | Miles',
      lpBrandName: 'NT',
      speciali18n: true,
      useJwtToken: false,
      UNIVERSAL_GET_JWTTOKEN_URL: 'aHR0cHM6Ly91YXR1bml2ZXJzYWx3cy5zY290aWEtcmV3YXJkcy5jb20vdjEvZ2V0SldUVG9rZW4=',
      UNIVERSAL_GET_JWTTOKEN_UN: 'ZnJvbnRBdXRoRW5kQXBwQWRtaW4=',
      UNIVERSAL_GET_JWTTOKEN_PW: 'ZnJvbnRBdXRoRW5kQXBwQWRtaW4yMDE4KiM=',
      //CONFIG_USER_GET_JWTTOKEN_URL: 'https://universalws.cryptmi.com/v1/getJWTTokenV2',
      CONFIG_USER_GET_JWTTOKEN_URL: 'https://devuniversalws.allegra.tech/v1/getCountry',
      //CONFIG_USER_GET_JWTTOKEN_URL: 'https://uat-universalws.alle2020.com/v1/getCountryV2',
      timeZoneDefault: 'QW1lcmljYS9Cb2dvdGE=',
      languajeTimeDefault: 'ZW4tVVM=',
      logo: 'icon.png',
      merchantAccountId: {
        benefits: 1,
        hotel: 2,
        flight: 3,
        activities: 4,
        travel: 5,
        cars: 6,
        transfers: 7,
        miles: 8,
        donate: 9
      },
      terminos_y_condiciones: {
        ES: '',
        EN: ''
      },
      config: {
        hasBenefits: true,
        hasActivities: true,
        hasHeaderDownload: true,
        hasTwoLanguages: true,
        isNewHome: true,
        isNewMenu: true,
        isLangNavigator: true,
        hasLandingSale: "mr2.sale",
        bannerHeaderBenefits: true
      },
      optionmenu: [
        { label: 'NAV.home', router: '/home', tabSelect: [EProduct.HOME] },
        { label: 'NAV.travel', router: '/travel', tabSelect: [EProduct.TRAVEL] }
      ],
      videosLandingEN: {
        salesDeck: {
          one: { url: 'https://player.vimeo.com/external/457782359.hd.mp4?s=38b569e549a38852627d25d41e4bb9c61082fc14&profile_id=175' },
          two: { url: 'https://player.vimeo.com/external/462698340.hd.mp4?s=4831102565ac2c2212934a453d5bd09530ff23c1&profile_id=175' },
          three: { url: 'https://player.vimeo.com/external/462698340.hd.mp4?s=4831102565ac2c2212934a453d5bd09530ff23c1&profile_id=175' },
          four: { url: 'https://player.vimeo.com/external/463606914.hd.mp4?s=db825b2b33e4796871325a2db3e92aed1fbb3e73&profile_id=175' },
          five: { url: 'https://player.vimeo.com/external/463577355.hd.mp4?s=5215a6a8d4ea54b6459994b227692dbf9a3f8ff2&profile_id=175' },
          six: { url: 'https://player.vimeo.com/external/464391578.hd.mp4?s=3504d011d22aca77668707c703531892ec9f7eed&profile_id=175' },
          seven: { url: 'https://player.vimeo.com/external/489997807.hd.mp4?s=785e1ce1c5cba48ed4ff045b45597266b659b885&profile_id=175' },
          eight: { url: 'https://player.vimeo.com/external/463194609.hd.mp4?s=412f1a5f980043f446a3fab5e3080f3e05c408fe&profile_id=175 ' },
          nine: { url: 'https://player.vimeo.com/external/463577347.hd.mp4?s=94fa831e69335ed6184d72c2029e0f4b4cdd9672&profile_id=175' },
        },
        sectionOne: {
          one: { url: 'https://player.vimeo.com/external/462698340.hd.mp4?s=4831102565ac2c2212934a453d5bd09530ff23c1&profile_id=175' },
          two: { url: 'https://player.vimeo.com/external/462698416.hd.mp4?s=ab891ae01fe13eb9e1f4927f6de49f984d5919e3&profile_id=175' },
          three: { url: 'https://player.vimeo.com/external/463606914.hd.mp4?s=db825b2b33e4796871325a2db3e92aed1fbb3e73&profile_id=175' }
        },
        sectionTwo: {
          one: { url: 'https://player.vimeo.com/external/462698781.hd.mp4?s=283cf3067ee649009c65da11de1b126a07235e7c&profile_id=175' },
          two: { url: 'https://player.vimeo.com/external/463304224.hd.mp4?s=9a735e7da10d17023936406d713cbfb2e1a859cf&profile_id=175' },
          three: { url: 'https://player.vimeo.com/external/463577289.hd.mp4?s=578a08311c6f5b60c33ccd510b4cff1d3891177c&profile_id=175' }
        },
        sectionThree: {
          one: { url: 'https://player.vimeo.com/external/463577355.hd.mp4?s=5215a6a8d4ea54b6459994b227692dbf9a3f8ff2&profile_id=175' },
          two: { url: 'https://player.vimeo.com/external/464391578.hd.mp4?s=3504d011d22aca77668707c703531892ec9f7eed&profile_id=175' },
          three: { url: 'https://player.vimeo.com/external/463194609.hd.mp4?s=412f1a5f980043f446a3fab5e3080f3e05c408fe&profile_id=175' }
        }
      },
      videosLandingES: {
        salesDeck: {
          one: { url: 'https://player.vimeo.com/external/483277508.hd.mp4?s=c6641ede521c89711d2a1cfe48ce77ced64edcf5&profile_id=175' },
          two: { url: 'https://player.vimeo.com/external/489998035.hd.mp4?s=fc17eb9c638071023140bd2335f6858b7f16d75e&profile_id=175' },
          three: { url: 'https://player.vimeo.com/external/463492992.hd.mp4?s=3ca8d80eed54324cbc192c8b42a02edcc28675bc&profile_id=175' },
          four: { url: 'https://player.vimeo.com/external/463606881.hd.mp4?s=2873823cf83208952b4f8f969a9040347394540d&profile_id=175' },
          five: { url: 'https://player.vimeo.com/external/464391355.hd.mp4?s=149c858c0b5a27225b08922f2d0e6bf35e40049f&profile_id=175' },
          six: { url: 'https://player.vimeo.com/external/463577357.hd.mp4?s=c9e05c260010388bec99e471f448f721e855ccd2&profile_id=175' },
          seven: { url: 'https://player.vimeo.com/external/489998012.hd.mp4?s=83791f7e30d7bf02f58ce0a878cef94b7009752a&profile_id=175' },
          eight: { url: 'https://player.vimeo.com/external/463492555.hd.mp4?s=23cb6c9a95eb46cbcd0dc165d42d77f0785a6ffd&profile_id=175' },
          nine: { url: 'https://player.vimeo.com/external/463577418.hd.mp4?s=f6b01e6e8349ec30f061cce4a6cb7b7f5e9317a8&profile_id=175' },
        },
        sectionOne: {
          one: { url: 'https://player.vimeo.com/external/463492992.hd.mp4?s=3ca8d80eed54324cbc192c8b42a02edcc28675bc&profile_id=175' },
          two: { url: 'https://player.vimeo.com/external/463493089.hd.mp4?s=07143435615817a02aab44f2a1099ce4c43705cc&profile_id=175' },
          three: { url: 'https://player.vimeo.com/external/463606881.hd.mp4?s=2873823cf83208952b4f8f969a9040347394540d&profile_id=175' }
        },
        sectionTwo: {
          one: { url: 'https://player.vimeo.com/external/470228705.hd.mp4?s=d198b0fe09e65353b545204717884ce24d29e04c&profile_id=175' },
          two: { url: 'https://player.vimeo.com/external/463493247.hd.mp4?s=d00a20fde5488d6ad75b9250c13770c9e14b2bd7&profile_id=175' },
          three: { url: 'https://player.vimeo.com/external/463577277.hd.mp4?s=4b9301389acd3d8f05c0ab8776037d97934e5e95&profile_id=175' }
        },
        sectionThree: {
          one: { url: 'https://player.vimeo.com/external/463577357.hd.mp4?s=c9e05c260010388bec99e471f448f721e855ccd2&profile_id=175' },
          two: { url: 'https://player.vimeo.com/external/464391355.hd.mp4?s=149c858c0b5a27225b08922f2d0e6bf35e40049f&profile_id=175' },
          three: { url: 'https://player.vimeo.com/external/463492555.hd.mp4?s=23cb6c9a95eb46cbcd0dc165d42d77f0785a6ffd&profile_id=175' }
        }
      },
      otherLinksEN: {
        salesDeck: {
          whatsapp: { url: '' },
          pdf_1: { url: '' },
          url_go2redeem: { url: '' }
        }
      },
      otherLinksES: {
        salesDeck: {
          whatsapp: { url: '' },
          pdf_1: { url: '' },
          url_go2redeem: { url: '' }
        }
      }
    }
  },

  google_analytics_gtag: 'G-FW8C2PNG8P',
  dataContactObj: {
    name: 'Bibiana',
    lastname: 'Montoya',
    email: 'contacto@allegrarewards.com',
    phone: '(+57) 3214344842',
    country: 'Colombia',
    city: 'Bogotá'
  }
};
